import { addLocaleData } from 'react-intl';

import 'moment/locale/pl';
import antdLocale from 'antd/lib/locale-provider/pl_PL';
import intlLocale from 'react-intl/locale-data/pl';
import messages from './messages.po';

addLocaleData(intlLocale);

export default { antdLocale, intlLocale, messages };
